import React, { useState } from "react"
import styled from "styled-components"
import { formatDate } from "../../../utils/date"

import {
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap"

const SessionViewWrapper = styled.div`
  .session-title {
    cursor: pointer;
  }
  .session-value {
    font-size: 14px;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.5);
  }
`
const modeOfTraining = [
  "Classroom",
  "Asynchronous eLearning",
  "In-house",
  "On-the-Job",
  "Practical / Practicum",
  "Supervised Field",
  "Traineeship",
  "Assessment",
  "Synchronous eLearning",
]
const SessionView = ({ sessions }) => {
  const [collapse, setCollapse] = useState(0)

  const toggle = (e) => {
    let event = e.target.dataset.event
    setCollapse(
      collapse === Number(event) ? -1 : Number(event)
    )
  }
  return (
    <SessionViewWrapper>
      <div className="container">
        {sessions.map((session, index) => {
          return (
            <Card
              style={{
                marginBottom: "1rem",
              }}
              key={index}
            >
              <CardHeader
                onClick={toggle}
                data-event={index}
                className="session-title"
              >
                Session start{" "}
                {formatDate(session.startDate)}
              </CardHeader>
              <Collapse isOpen={collapse === index}>
                <CardBody>
                  <Container>
                    <Row>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Mode Of Training</b>
                        </span>
                        <span className="session-value">
                          {
                            modeOfTraining[
                              parseInt(
                                session.modeOfTraining
                              ) + 1
                            ]
                          }
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs="3">
                        <span className="d-block">
                          <b>Start Date</b>
                        </span>
                        <span className="session-value">
                          {formatDate(session.startDate)}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Start Time</b>
                        </span>
                        <span className="session-value">
                          {session.startTime}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>End Date</b>
                        </span>
                        <span className="session-value">
                          {formatDate(session.endDate)}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>End Time</b>
                        </span>
                        <span className="session-value">
                          {session.endTime}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs="3">
                        <span className="d-block">
                          <b>Street</b>
                        </span>
                        <span className="session-value">
                          {session.venueStreet}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Building</b>
                        </span>
                        <span className="session-value">
                          {session.venueBuilding}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Floor</b>
                        </span>
                        <span className="session-value">
                          {session.venueFloor}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Block</b>
                        </span>
                        <span className="session-value">
                          {session.venueBlock}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs="3">
                        <span className="d-block">
                          <b>Unit</b>
                        </span>
                        <span className="session-value">
                          {session.venueUnit}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Room</b>
                        </span>
                        <span className="session-value">
                          {session.venueRoom}
                        </span>
                      </Col>
                      <Col xs="3">
                        <span className="d-block">
                          <b>Postal Code</b>
                        </span>
                        <span className="session-value">
                          {session.venuePostalCode}
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Collapse>
            </Card>
          )
        })}
      </div>
    </SessionViewWrapper>
  )
}

export default SessionView
