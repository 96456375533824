import React, { useState, useMemo, useEffect } from "react"
import Amplify, {
  Auth,
  API,
  graphqlOperation,
} from "aws-amplify"
import { listCourseRuns } from "../../../graphql/queries"
import { deleteCourseRun } from "../../../graphql/mutations"
import { withAuthenticator } from "@aws-amplify/ui-react"
import { isAuthenticatedUserMemberOfAdmin } from "../../../utils/auth"
import { onAuthUIStateChange } from "@aws-amplify/ui-components"
import Layout from "components/layout"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Table from "../../../widgets/Table"
import SessionView from "components/admin/runs/session-view"

const RunsEdit = () => {
  const [allRuns, setAllRuns] = useState([])
  const [deleteRow, setDeleteRow] = useState(null)
  const [modal, setModal] = useState(false)
  const [user, setUser] = React.useState()
  React.useEffect(() => {
    return onAuthUIStateChange(
      (nextAuthState, authData) => {
        setUser(authData)
      }
    )
  }, [])
  const toggle = () => setModal(!modal)

  const columns = useMemo(
    () => [
      {
        Header: "RunID",
        accessor: "ssgRunID",
      },
      {
        Header: "Course Ref",
        accessor: "referenceNumber",
      },
      {
        Header: "Start Date",
        accessor: (row) => formatDate(row.startDate),
      },
      {
        Header: "Course Name",
        accessor: "title",
        disableFilters: true,
      },
      {
        Header: "Course Admin Email",
        accessor: "courseAdminEmail",
        disableFilters: true,
      },
      {
        Header: "Mode of Training",
        accessor: (row) =>
          [
            "Classroom",
            "Asynchronous eLearning",
            "In-house",
            "On-the-Job",
            "Practical / Practicum",
            "Supervised Field",
            "Traineeship",
            "Assessment",
            "Synchronous eLearning",
          ][parseInt(row.modeOfTraining) - 1],
        disableFilters: true,
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <Button
            color="primary"
            {...row.getToggleRowExpandedProps()}
          >
            {row.isExpanded
              ? "HIDE SESSIONS"
              : "VIEW SESSIONS"}
          </Button>
        ),
      },
      {
        Header: () => null,
        id: "delete",
        Cell: ({ row }) => (
          <Button
            color="danger"
            onClick={() => {
              //const dataCopy = [...allRuns]
              //dataCopy.splice(row.index, 1)
              setDeleteRow(row.index)
              toggle()
              //setAllRuns(dataCopy)
            }}
          >
            Delete
          </Button>
        ),
      },
    ],
    [allRuns]
  )

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [day, month, year].join("/")
  }
  async function deleteCourseRuns(index) {
    try {
      const result = await API.graphql({
        query: deleteCourseRun,
        variables: {
          input: {
            id: allRuns[index].id,
          },
        },
      })
      if (!result.hasOwnProperty("errors")) {
        fetchCourseRuns()
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function fetchCourseRuns() {
    try {
      const response = await API.graphql(
        graphqlOperation(listCourseRuns)
      )
      setAllRuns(response.data.listCourseRuns.items)
    } catch (err) {
      console.log("error fetch course run: ", err)
    }
  }
  useEffect(() => {
    fetchCourseRuns()
  }, [])

  useEffect(() => {}, [])

  const renderRowSubComponent = React.useCallback(
    ({ row, data }) => (
      <SessionView sessions={data[row.index].sessions} />
    ),
    []
  )
  const isMemberOfAdmin = user
    ? isAuthenticatedUserMemberOfAdmin(user)
    : false

  return isMemberOfAdmin ? (
    <Layout>
      <div className="p-4">
        <div className="table-wrapper">
          <h3>All Course Runs</h3>
          <div className="operator">
            <p></p>
          </div>
          <Table
            columns={columns}
            data={allRuns}
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="text-center">
            <h4>Are you sure?</h4>
            {deleteRow != null && allRuns.length > 0 ? (
              <p>
                You want to delete Run{" "}
                {allRuns[deleteRow].ssgRunID}
              </p>
            ) : (
              false
            )}
          </ModalBody>
          <ModalFooter className="justify-content-around">
            <Button
              color="primary"
              onClick={() => {
                deleteCourseRuns(deleteRow)
                setDeleteRow(null)
                toggle()
              }}
              className="w-25"
            >
              Yes
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                setDeleteRow(null)
                toggle()
              }}
              className="w-25"
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Layout>
  ) : (
    <Layout>
      <h1 class="text-center pt-4">
        You are not allowed to access this page.
      </h1>
    </Layout>
  )
}

export default withAuthenticator(RunsEdit)
